import * as React from "react";
const SvgLogo = (props) => /* @__PURE__ */ React.createElement("svg", { id: "Layer_1", "data-name": "Layer 1", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", viewBox: "0 0 2259 899.6", ...props }, /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("symbol", { id: "a", "data-name": "a", viewBox: "0 0 46.5 46.6" }, /* @__PURE__ */ React.createElement("rect", { width: 46.5, height: 46.6, style: {
  fill: "#525253"
} }))), /* @__PURE__ */ React.createElement("title", null, "FOND"), /* @__PURE__ */ React.createElement("path", { d: "M278,49H52V291H272v40H52V676H8V9H278Z", transform: "translate(-8)", style: {
  fill: "#525252"
} }), /* @__PURE__ */ React.createElement("path", { d: "M1039,344c0,192-151,343-346,343-196,0-346-151-346-343S498,0,693,0,1039,152,1039,344Zm-46,0C993,173,865,40,693,40S393,173,393,344c0,170,128,303,300,303S993,514,993,344Z", transform: "translate(-8)", style: {
  fill: "#525252"
} }), /* @__PURE__ */ React.createElement("path", { d: "M1108.4,676V2l560,586V9h44V693l-560-586V676Z", transform: "translate(-8)", style: {
  fill: "#525252"
} }), /* @__PURE__ */ React.createElement("path", { d: "M1793,677V10h132c59,0,162,9,246,93,71,71,96,168,96,241s-25,169-96,240c-84,84-187,93-246,93Zm44-40h87c176,0,297-120,297-293,0-171-122-294-297-294h-87Z", transform: "translate(-8)", style: {
  fill: "#525252"
} }), /* @__PURE__ */ React.createElement("line", { x1: 661.3, y1: 421.8, x2: 821.3, y2: 421.3, style: {
  fill: "none",
  stroke: "#525252",
  strokeWidth: "14.25px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 674.3, y1: 408.9, x2: 873.2, y2: 194.8, style: {
  fill: "none",
  stroke: "#525252",
  strokeWidth: "14.25px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 659.3, y1: 410.9, x2: 691.5, y2: 247.2, style: {
  fill: "none",
  stroke: "#525252",
  strokeWidth: "14.25px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 645.9, y1: 417.3, x2: 511.2, y2: 185.6, style: {
  fill: "none",
  stroke: "#525252",
  strokeWidth: "14.25px"
} }), /* @__PURE__ */ React.createElement("line", { x1: 576.5, y1: 571.3, x2: 655.3, y2: 426.4, style: {
  fill: "none",
  stroke: "#525252",
  strokeWidth: "14.25px"
} }), /* @__PURE__ */ React.createElement("rect", { x: 807.4, y: 388.5, width: 65.8, height: 65.7, style: {
  fill: "#525252"
} }), /* @__PURE__ */ React.createElement("rect", { x: 656, y: 209.4, width: 65.8, height: 65.7, style: {
  fill: "#525252"
} }), /* @__PURE__ */ React.createElement("rect", { x: 543.6, y: 538.5, width: 65.8, height: 65.7, style: {
  fill: "#525252"
} }), /* @__PURE__ */ React.createElement("rect", { x: 478.6, y: 154.8, width: 65.8, height: 65.7, style: {
  fill: "#525252"
} }), /* @__PURE__ */ React.createElement("use", { width: 46.5, height: 46.6, transform: "matrix(1.41, 0, 0, -1.41, 840.22, 227.24)", xlinkHref: "#a" }), /* @__PURE__ */ React.createElement("rect", { x: 610.3, y: 375, width: 90, height: 84.8, style: {
  fill: "#ee1c24"
} }), /* @__PURE__ */ React.createElement("path", { d: "M56.52,765.06H21v38H55.58v6.28H21v54.16H14.13V758.77H56.52Z", transform: "translate(-8)", style: {
  fill: "#525252"
} }), /* @__PURE__ */ React.createElement("path", { d: "M67.67,778.09a5.65,5.65,0,1,1,5.65,5.8A5.57,5.57,0,0,1,67.67,778.09Zm8.79,21.35v64H70.18v-64Z", transform: "translate(-8)", style: {
  fill: "#525252"
} }), /* @__PURE__ */ React.createElement("path", { d: "M105.35,749v61.54A30.1,30.1,0,0,1,130.47,798c19.31,0,31.08,14.45,31.08,33,0,18.84-11.93,34.23-31.4,34.23-15.86,0-22.92-10.05-24.8-13.19v11.46H99.07V749ZM155,831c0-17.58-11.62-27-24.81-27-12.56,0-25.12,9-25.12,26.69,0,16,8.79,28.57,25,28.57S155,846.69,155,831Z", transform: "translate(-8)", style: {
  fill: "#525252"
} }), /* @__PURE__ */ React.createElement("path", { d: "M182.59,832.25c0,16.33,11.93,27,25.43,27,10.36,0,18.21-6.12,22.29-14.6l5.34,3.14c-3.76,7.23-12.24,17.43-28.1,17.43-19.94,0-31.56-14.76-31.56-33.44,0-22.29,15.39-33.76,31.09-33.76,17.27,0,30.3,13.82,30.3,34.23Zm48.2-6c-1.57-12.71-11-22.29-23.71-22.29-13,0-22.29,9.89-24,22.29Z", transform: "translate(-8)", style: {
  fill: "#525252"
} }), /* @__PURE__ */ React.createElement("path", { d: "M255.9,799.44h6.29v8.32c2.35-5.5,7.22-9.74,14.12-9.74a15,15,0,0,1,6.76,1.57l-3.14,5.66a7.27,7.27,0,0,0-4.71-1.26c-12.88,0-13,17.11-13,25.12v34.38H255.9Z", transform: "translate(-8)", style: {
  fill: "#525252"
} }), /* @__PURE__ */ React.createElement("path", { d: "M439.12,811.37c0,30.14-23.71,53.85-54.32,53.85-30.77,0-54.32-23.71-54.32-53.85s23.7-54,54.32-54S439.12,781.23,439.12,811.37Zm-7.22,0c0-26.85-20.1-47.73-47.1-47.73s-47.1,20.88-47.1,47.73c0,26.69,20.09,47.57,47.1,47.57S431.9,838.06,431.9,811.37Z", transform: "translate(-8)", style: {
  fill: "#525252"
} }), /* @__PURE__ */ React.createElement("path", { d: "M465.18,899.6H458.9V799.44h6.28v11.14A30.11,30.11,0,0,1,490.3,798c19.31,0,31.09,14.45,31.09,33,0,18.84-11.94,34.23-31.4,34.23-15.86,0-22.93-10.05-24.81-13.19ZM514.79,831c0-17.58-11.62-27-24.8-27-12.56,0-25.12,9-25.12,26.69,0,16,8.79,28.57,25,28.57S514.79,846.69,514.79,831Z", transform: "translate(-8)", style: {
  fill: "#525252"
} }), /* @__PURE__ */ React.createElement("path", { d: "M546.66,805.4v58.09h-6.28V805.4h-7.06v-6h7.06V776.52h6.28v22.92h11.15v6Z", transform: "translate(-8)", style: {
  fill: "#525252"
} }), /* @__PURE__ */ React.createElement("path", { d: "M567.86,778.09a5.65,5.65,0,1,1,5.65,5.8A5.57,5.57,0,0,1,567.86,778.09Zm8.79,21.35v64h-6.28v-64Z", transform: "translate(-8)", style: {
  fill: "#525252"
} }), /* @__PURE__ */ React.createElement("path", { d: "M650.59,814.51A29.25,29.25,0,0,0,628.46,804c-15.7,0-26.69,12.09-26.69,27.79,0,18.37,14,27.47,26.37,27.47,10.21,0,18.21-6,23.24-11.46v8.48a34.93,34.93,0,0,1-23.4,9c-19.31,0-32.81-14.29-32.81-33.28S607.89,798,628.46,798a33.65,33.65,0,0,1,22.13,8.17Z", transform: "translate(-8)", style: {
  fill: "#525252"
} }), /* @__PURE__ */ React.createElement("path", { d: "M708.05,863.49V757.68l87.92,92V758.77h6.91V866.16l-87.92-92v89.33Z", transform: "translate(-8)", style: {
  fill: "#525252"
} }), /* @__PURE__ */ React.createElement("path", { d: "M830.82,832.25c0,16.33,11.94,27,25.44,27,10.36,0,18.21-6.12,22.29-14.6l5.34,3.14c-3.77,7.23-12.25,17.43-28.1,17.43-19.94,0-31.56-14.76-31.56-33.44,0-22.29,15.39-33.76,31.09-33.76,17.27,0,30.3,13.82,30.3,34.23Zm48.2-6c-1.57-12.71-11-22.29-23.7-22.29-13,0-22.3,9.89-24,22.29Z", transform: "translate(-8)", style: {
  fill: "#525252"
} }), /* @__PURE__ */ React.createElement("path", { d: "M910.89,805.4v58.09h-6.28V805.4h-7.06v-6h7.06V776.52h6.28v22.92H922v6Z", transform: "translate(-8)", style: {
  fill: "#525252"
} }), /* @__PURE__ */ React.createElement("path", { d: "M934.6,799.44l22.13,52.91,22.14-56.84L1001,852.35l22.76-52.91h6.91l-29.67,68-22.14-56.84-22.14,56.84-29-68Z", transform: "translate(-8)", style: {
  fill: "#525252"
} }), /* @__PURE__ */ React.createElement("path", { d: "M1106.35,831.62c0,19-14.6,33.6-33.6,33.6s-33.59-14.6-33.59-33.6,14.6-33.6,33.59-33.6S1106.35,812.63,1106.35,831.62Zm-6.59,0c0-15.54-11.62-27.63-27-27.63s-27,12.09-27,27.63,11.62,27.63,27,27.63S1099.76,847.16,1099.76,831.62Z", transform: "translate(-8)", style: {
  fill: "#525252"
} }), /* @__PURE__ */ React.createElement("path", { d: "M1124.88,799.44h6.28v8.32c2.35-5.5,7.22-9.74,14.13-9.74a15,15,0,0,1,6.75,1.57l-3.14,5.66a7.27,7.27,0,0,0-4.71-1.26c-12.88,0-13,17.11-13,25.12v34.38h-6.28Z", transform: "translate(-8)", style: {
  fill: "#525252"
} }), /* @__PURE__ */ React.createElement("path", { d: "M1172,749v77.87l26.37-27.47h8.16l-27,27.79,35.8,36.26h-8.48l-31.56-31.71-3.29,3.3v28.41h-6.28V749Z", transform: "translate(-8)", style: {
  fill: "#525252"
} }), /* @__PURE__ */ React.createElement("path", { d: "M1267.27,863.49V758.77H1288c9.26,0,25.43,1.42,38.62,14.61,11.14,11.14,15.07,26.37,15.07,37.83s-3.93,26.53-15.07,37.68c-13.19,13.19-29.36,14.6-38.62,14.6Zm6.91-6.28h13.66c27.63,0,46.63-18.84,46.63-46,0-26.84-19.16-46.15-46.63-46.15h-13.66Z", transform: "translate(-8)", style: {
  fill: "#525252"
} }), /* @__PURE__ */ React.createElement("path", { d: "M1364,832.25c0,16.33,11.94,27,25.44,27,10.36,0,18.21-6.12,22.29-14.6l5.34,3.14c-3.77,7.23-12.25,17.43-28.1,17.43-19.94,0-31.56-14.76-31.56-33.44,0-22.29,15.38-33.76,31.08-33.76,17.27,0,30.31,13.82,30.31,34.23Zm48.2-6c-1.57-12.71-11-22.29-23.71-22.29-13,0-22.29,9.89-24,22.29Z", transform: "translate(-8)", style: {
  fill: "#525252"
} }), /* @__PURE__ */ React.createElement("path", { d: "M1464.62,811.21a11.72,11.72,0,0,0-11.15-7.22c-7.22,0-10.83,5.5-10.83,10.21,0,6.27,5,8.32,11.3,10.83,6.44,2.51,12.4,4.24,16.49,9.42a15.44,15.44,0,0,1,3.29,10.36c0,14.6-11,20.41-20.88,20.41-10.67,0-19.47-6.28-22.13-17l6.12-1.88c2.2,8.48,9,12.87,16,12.87,8.17,0,14.29-5.81,14.29-14.13,0-7.22-4.55-9.89-14.29-13.81-6.43-2.67-11.14-4.71-14-8.48a13.45,13.45,0,0,1-2.83-8.32c0-9.73,7.38-16.49,17.12-16.49,7.53,0,13.81,3.46,16.79,10.37Z", transform: "translate(-8)", style: {
  fill: "#525252"
} }), /* @__PURE__ */ React.createElement("path", { d: "M1487.23,778.09a5.65,5.65,0,1,1,5.65,5.8A5.57,5.57,0,0,1,1487.23,778.09Zm8.79,21.35v64h-6.28v-64Z", transform: "translate(-8)", style: {
  fill: "#525252"
} }), /* @__PURE__ */ React.createElement("path", { d: "M1577.66,799.44v62.17c0,24.18-12.09,38-32.19,38-16.64,0-30.61-9.73-31.4-27.47h6.6c1.09,13.34,11.14,21.51,24.8,21.51,25.28,0,25.91-25.44,25.91-32V852.5a30.71,30.71,0,0,1-25.28,12.72c-15.7,0-31.56-11.46-31.56-33.75,0-18.53,12.25-33.45,32.34-33.45a29,29,0,0,1,24.5,12.56V799.44Zm-56.52,32.34c0,15.38,9.73,27.47,24.8,27.47,15.86,0,25.75-12.4,25.75-28.1,0-14.91-9.73-27.16-25.43-27.16C1530.4,804,1521.14,817,1521.14,831.78Z", transform: "translate(-8)", style: {
  fill: "#525252"
} }), /* @__PURE__ */ React.createElement("path", { d: "M1600.26,799.44h6.28v10A21.39,21.39,0,0,1,1626.17,798c11.46,0,16.32,6.76,17.74,9,2.82,4.56,3.14,10.52,3.14,16.17v40.35h-6.28V823.14c0-4.39-.16-9.26-2.52-12.87-1.41-2.35-4.86-6.28-12.55-6.28-8.8,0-13.35,4.71-15.7,9-2.83,5-3.46,11-3.46,17.27v33.28h-6.28Z", transform: "translate(-8)", style: {
  fill: "#525252"
} }), /* @__PURE__ */ React.createElement("path", { d: "M1756.79,776.52c-6.44-12.56-17.75-12.88-20.57-12.88-12.4,0-19.47,9-19.47,19.47,0,11.3,7.85,15.07,18.37,20.25,10.36,5,15.54,7.22,20.88,11,4.55,3.14,11.15,8.64,11.15,20.25,0,17.59-14.76,30.62-32.66,30.62-13.5,0-20.41-6.44-24.33-11a34.38,34.38,0,0,1-6.75-11.46l6-2.83a36.36,36.36,0,0,0,5.65,10c4.71,6,12.41,9,19.63,9,14.13,0,25.28-10.36,25.28-24.18,0-5.65-2.2-11.62-10.21-16.48-3-1.73-9.1-4.87-17.74-9.11-10.36-5-15.54-7.85-19.15-13.81a22.49,22.49,0,0,1-3.3-11.94c0-12.08,8.16-26.06,26.85-26.06,10.51,0,20.09,5,25.43,14.92Z", transform: "translate(-8)", style: {
  fill: "#525252"
} }), /* @__PURE__ */ React.createElement("path", { d: "M1784.42,778.09a5.65,5.65,0,1,1,5.65,5.8A5.57,5.57,0,0,1,1784.42,778.09Zm8.79,21.35v64h-6.28v-64Z", transform: "translate(-8)", style: {
  fill: "#525252"
} }), /* @__PURE__ */ React.createElement("path", { d: "M1815.82,799.44h6.28v8a18.23,18.23,0,0,1,16.64-9.42c12.56,0,16.64,8.33,17.9,11.62,3.61-7.69,10.51-11.62,19-11.62,7.07,0,11.62,2.52,14.45,5.34,5.18,5.18,5.49,12.25,5.49,17.43v42.7h-6.28v-42.7c0-4.24-.31-9.58-3.77-13.19-2.82-3-6.59-3.61-9.57-3.61-17,0-17.12,17-17.12,20.88v38.62h-6.28V822.36c0-4.08-.31-18.37-14.13-18.37-11.14,0-16.32,9.73-16.32,22.45v37h-6.28Z", transform: "translate(-8)", style: {
  fill: "#525252"
} }), /* @__PURE__ */ React.createElement("path", { d: "M1924.46,899.6h-6.28V799.44h6.28v11.14A30.1,30.1,0,0,1,1949.58,798c19.31,0,31.08,14.45,31.08,33,0,18.84-11.93,34.23-31.4,34.23-15.85,0-22.92-10.05-24.8-13.19ZM1974.07,831c0-17.58-11.62-27-24.81-27-12.56,0-25.12,9-25.12,26.69,0,16,8.8,28.57,25,28.57S1974.07,846.69,1974.07,831Z", transform: "translate(-8)", style: {
  fill: "#525252"
} }), /* @__PURE__ */ React.createElement("path", { d: "M2005.47,749V863.49h-6.28V749Z", transform: "translate(-8)", style: {
  fill: "#525252"
} }), /* @__PURE__ */ React.createElement("path", { d: "M2025.56,778.09a5.66,5.66,0,1,1,5.66,5.8A5.57,5.57,0,0,1,2025.56,778.09Zm8.8,21.35v64h-6.28v-64Z", transform: "translate(-8)", style: {
  fill: "#525252"
} }), /* @__PURE__ */ React.createElement("path", { d: "M2063.24,805.4v58.09H2057V805.4h-7.22v-6H2057V773.85c0-9.89,1.1-13.5,3.46-17.12,6.12-8.94,14.13-9.57,19.94-9.57s13.81.63,19.93,9.57c2.36,3.62,3.46,7.23,3.46,17.12v2.67h-6.28v-2.67c0-4.08,0-9.42-2.67-13.82-3.77-6.28-10.36-6.91-14.44-6.91s-10.68.79-14.45,6.91c-2.67,4.4-2.67,10.05-2.67,13.82v25.59h16v6Zm40.51-6v64h-6.28v-64Z", transform: "translate(-8)", style: {
  fill: "#525252"
} }), /* @__PURE__ */ React.createElement("path", { d: "M2128.87,832.25c0,16.33,11.93,27,25.43,27,10.36,0,18.21-6.12,22.29-14.6l5.34,3.14c-3.77,7.23-12.24,17.43-28.1,17.43-19.94,0-31.56-14.76-31.56-33.44,0-22.29,15.39-33.76,31.09-33.76,17.27,0,30.3,13.82,30.3,34.23Zm48.19-6c-1.56-12.71-11-22.29-23.7-22.29-13,0-22.3,9.89-24,22.29Z", transform: "translate(-8)", style: {
  fill: "#525252"
} }), /* @__PURE__ */ React.createElement("path", { d: "M2254.31,749h6.28V863.49h-6.28V852a29.19,29.19,0,0,1-24.81,13.19c-16.17,0-31.4-11.62-31.4-34.07,0-23.39,15.86-33.13,31.4-33.13,11.62,0,19.78,5.34,24.81,12.56Zm-49.61,82c0,18.69,12.24,28.26,25.12,28.26,12.71,0,24.8-9.57,24.8-28.26,0-14.91-9.26-27-25.12-27C2216.31,804,2204.7,813.1,2204.7,831Z", transform: "translate(-8)", style: {
  fill: "#525252"
} }));
export default SvgLogo;

import React from "react";
import { useNavigate, useParams } from "react-router";
import { ColorLens } from "@mui/icons-material";
import { ListItemIcon, MenuItem } from "@mui/material";

import { usePermissionCheck } from "fond/hooks/usePermissionCheck";
import { PermissionLevel } from "fond/types";
import { Actions } from "fond/utils/permissions";

interface IProps {
  permissionLevel: PermissionLevel;
  handleClose: () => void;
}

const StyleEditor: React.FC<IProps> = ({ permissionLevel, handleClose }: IProps) => {
  const { uuid: versionId } = useParams<{ uuid: string }>();
  const navigate = useNavigate();
  const canEdit = usePermissionCheck(Actions.PROJECT_EDIT_STYLES, permissionLevel);

  /**
   * Handles the redirection to the style editor
   */
  const handleClick = () => {
    if (canEdit) {
      handleClose();
      navigate(`/styles/${versionId}`);
    }
  };

  return (
    <MenuItem data-testid="styles-editor-button" onClick={handleClick} disabled={!canEdit}>
      <ListItemIcon>
        <ColorLens />
      </ListItemIcon>
      Style Editor
    </MenuItem>
  );
};

export default StyleEditor;

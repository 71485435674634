import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { AgGridReact } from "@ag-grid-community/react";
import SyncIcon from "@mui/icons-material/Sync";
import { Box, Button, Grid, Typography } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";
import dayjs from "dayjs";

import { Card } from "ui/Card";

import {
  useGetMultiProjectStatisticsQuery,
  useGetMultiReportQuery,
  useGetMultiReportsQuery,
  useGetReportsByMultiReportQuery,
  useGetReportsQuery,
} from "fond/api";
import { MULTI_REPORT_FOLDER_ID } from "fond/constants";
import MapProvider from "fond/map/MapProvider";
import CityReportCallout from "fond/projects/CityReport/CityReportCallout";
import CityReportMap from "fond/projects/CityReport/CityReportMap";
import CityReportStatistics from "fond/projects/CityReport/CityReportStatistics";
import SubareaReportList from "fond/projects/CityReport/SubareaReportList";
import { NoResourceIcon } from "fond/svg_icons";
import { MultiProject, MultiReport, MultiReportCategory, Report, Store } from "fond/types";
import { useQueryParams } from "fond/utils/hooks";
import SkeletonLoadingGrid from "fond/widgets/SkeletonLoadingGrid";
import { ZoomableGridCard } from "fond/widgets/ZoomableGridCard";

import CityReportSelectModal from "./CityReportSelectModal";

interface IProps {
  multiProject?: MultiProject;
  multiReport?: MultiReport;
}

/**
 * A main page content displaying a market lens report.
 * Includes a DataGrid supporting column sorting and pagination
 */
const CityReportFolderPageContent: React.FC<IProps> = ({ multiProject, multiReport }: IProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const gridRef = useRef<AgGridReact>(null);
  const isCityHub = pathname.startsWith("/cities/");
  const multiReportFolderId = useQueryParams(MULTI_REPORT_FOLDER_ID);
  const [showReportSelection, setShowReportSelection] = useState(false);
  const [selectedMultiReport, setSelectedMultiReport] = useState<MultiReport | null>(multiReport ?? null);
  const [multiReportCategory, setMultiReportCategory] = useState<MultiReportCategory>("Npv");
  const [sliderRange, setSliderRange] = useState<number[] | null>(null);
  const [filterRange, setFilterRange] = useState<number[] | null>(null);
  const [filteredNodes, setFilteredNodes] = useState<Report[] | null>(null);

  const { data: multiReports } = useGetMultiReportsQuery(multiProject?.ID ?? skipToken);
  const { data: fullMultiReport } = useGetMultiReportQuery(selectedMultiReport?.ID ?? skipToken);
  const { data: reports } = useGetReportsByMultiReportQuery(selectedMultiReport?.ID ?? skipToken);
  const currentUsername = useSelector((state: Store) => state.cognito.user?.username);
  const lastModifiedBy = selectedMultiReport?.LastModifiedBy.Email === currentUsername ? "me" : selectedMultiReport?.LastModifiedBy.Email;

  const noPlan = !selectedMultiReport && multiProject && !multiProject?.Areas.some((area) => area.Project?.ID);
  const noReport = !selectedMultiReport && multiProject && !noPlan && multiReports?.length === 0;
  const { data: statistics } = useGetMultiProjectStatisticsQuery(multiProject?.ID ?? skipToken);

  if (!selectedMultiReport && multiReports && multiReports.length > 0) {
    setSelectedMultiReport(multiReports.find((report) => report.Folder.ID === multiReportFolderId) || multiReports[0]);
  }

  // Keep polling until all subarea reports are in a terminal state.
  const allFinished = reports?.every((report) => ["COMPLETE", "ERROR"].includes(report?.Status?.State ?? "")) ?? false;
  useGetReportsQuery(selectedMultiReport?.ID ?? skipToken, { pollingInterval: allFinished ? 0 : 30_000 });

  const handleViewOtherReport = () => {
    setShowReportSelection(true);
  };

  useEffect(() => {
    setSliderRange(null);
    setFilterRange(null);
    setFilteredNodes(reports ?? null);
  }, [selectedMultiReport, multiReportCategory, reports]);

  // Keep the URL updated when select a different report to view
  useEffect(() => {
    if (isCityHub && selectedMultiReport && multiProject) {
      navigate(`/cities/${multiProject.Folder.ID}?${MULTI_REPORT_FOLDER_ID}=${selectedMultiReport.Folder?.ID}`, { state: { tab: "report" } });
    }
  }, [isCityHub, selectedMultiReport, multiProject, navigate]);

  if (noPlan || noReport) {
    return (
      <Card
        title={noPlan ? "Generate a plan first" : "It looks like you don't have reports yet"}
        description={
          noPlan ? "Complete the plan step before generating a report." : "Generate reports to perform a financial analysis on your subareas."
        }
        icon={<NoResourceIcon />}
        buttonText={noPlan ? "Generate plan" : "Generate report"}
        onClick={() => {
          navigate(`/city/${multiProject.ID}`);
        }}
      />
    );
  }

  if (!selectedMultiReport)
    return (
      <SkeletonLoadingGrid
        items={[
          { height: 576, sizes: { xs: 12, sm: 4 } },
          { height: 576, sizes: { xs: 12, sm: 8 } },
          { height: 300, sizes: { xs: 12, sm: 12 } },
        ]}
      />
    );

  return (
    <Box>
      <Box mb={2} display="flex" alignItems="center" justifyContent="space-between">
        <Box mt={2} display="flex" alignItems="center">
          <Typography variant="h5">{selectedMultiReport?.Name}</Typography>
          {isCityHub && (
            <Button startIcon={<SyncIcon />} variant="outlined" sx={{ ml: 2 }} onClick={handleViewOtherReport}>
              Switch to another report
            </Button>
          )}
        </Box>
        <Typography variant="caption">
          {`Last Modified by ${lastModifiedBy} at ${dayjs(selectedMultiReport?.LastModified).format("MMM D, YYYY h:mm A")}`}
        </Typography>
      </Box>
      <Typography>{selectedMultiReport?.Description}</Typography>
      <Grid container spacing={2.5} sx={{ mt: 2 }}>
        {filteredNodes && (
          <Grid item lg={4} xs={12}>
            <Grid container spacing={2.5} sx={{ height: "100%" }}>
              <CityReportCallout filteredNodes={filteredNodes} />
              {statistics && <CityReportStatistics filteredNodes={filteredNodes} statistics={statistics} />}
            </Grid>
          </Grid>
        )}
        {multiProject && fullMultiReport && (
          <ZoomableGridCard breakpoints={{ lg: 8, xs: 12 }} height={576} title="Project map">
            <MapProvider mapStyle="monochrome">
              <CityReportMap
                multiProject={multiProject}
                multiReport={fullMultiReport}
                reports={reports ?? []}
                multiReportCategory={multiReportCategory}
                setMultiReportCategory={setMultiReportCategory}
                sliderRange={sliderRange}
                setSliderRange={setSliderRange}
                filterRange={filterRange}
              />
            </MapProvider>
          </ZoomableGridCard>
        )}
      </Grid>
      {fullMultiReport && (
        <SubareaReportList
          gridRef={gridRef}
          multiReport={fullMultiReport}
          reports={reports ?? []}
          sliderRange={sliderRange}
          multiReportCategory={multiReportCategory}
          filteredNodes={filteredNodes}
          setFilterRange={setFilterRange}
          setFilteredNodes={setFilteredNodes}
        />
      )}

      {showReportSelection && multiReports && (
        <CityReportSelectModal
          multiReports={multiReports}
          onClose={() => {
            setShowReportSelection(false);
          }}
          onSelect={setSelectedMultiReport}
        />
      )}
    </Box>
  );
};

export default CityReportFolderPageContent;

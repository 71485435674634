import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { Feature, GeoJSON, GeoJsonProperties, Geometry, LineString, Point, Position } from "geojson";
import mapboxgl from "mapbox-gl";

const {
  lib: { ModeHandler },
} = MapboxDraw;

export type MapboxDrawBase = {
  options?: {
    snap: boolean;
  };
} & MapboxDraw;

// We extend the api within /draw/store.js to include the following functions:
export type MapboxDrawExtended = MapboxDrawBase & {
  ctx: {
    map: mapboxgl.Map;
    events: {
      getCurrentMode(): ReturnType<typeof ModeHandler>;
    };
  };

  commitPole: (args: {
    selectedPole: Feature<Point>;
    originalPole?: Feature<Point> | null;
    connectedSpans?: Feature<LineString>[] | [];
    snap: { feature: Feature; coords: Position | undefined } | null;
  }) => void;

  commitSpans: (args: { lineString: GeoJSON; snaps: Array<{ feature: Feature<Geometry, GeoJsonProperties>; coords: Position }> }) => {
    features: Feature[];
    selectedFeatureId: string;
  };
  getFeatureById: (id: string | number) => { layerId: string; isEditing: boolean; feature: Feature };

  isDeletedFeature: (id: string | number) => boolean;

  iterFeatures: (layerId: string) => [[Feature, boolean]];

  markFeatureDeleted: (featureId?: string | number) => void;

  _markFeatureDeleted: (featureId?: string | number) => void;

  markPoleSnapped: (poleId: string | number | undefined, isSnapped: boolean) => void;

  mergePoles: (selectedPole: Feature<Point>) => void;

  pullInFeature: (layerId: string, feature: Feature) => void;

  pullInFeatures: (features: Array<{ layerId: string; feature: Feature }>) => void;

  pullInPole: (clickedPole: Feature) => void;

  refreshUnsnappedPoles: () => void;

  revertAll: () => void;

  splitSpanAtCoords: (span: Feature<Geometry, GeoJsonProperties>, coords: Position) => void;

  changeMode(mode: string, opts?: object, eventOpts?: object): void;
};

import { Box, IconButton as MuiIconButton, styled, TextField as MuiTextField } from "@mui/material";
import Filter from "svg_icons/filter.svg?react";

export const Container = styled(Box)(({ theme }) => ({
  position: "sticky",
  top: 0,
  backgroundColor: theme.palette.common.white,
  zIndex: 1,
  display: "flex",
  flexGrow: 1,
  marginRight: theme.spacing(0.5),
  marginLeft: theme.spacing(0.5),
  alignItems: "center",
}));

export const TextField = styled(MuiTextField)(({ theme }) => ({
  margin: 0,
  marginLeft: theme.spacing(1),
  "& .MuiOutlinedInput-root": {
    height: 26,
    paddingRight: 0,
  },
  "& .MuiOutlinedInput-input": {
    fontSize: 12,
    padding: theme.spacing(0.5),
    color: theme.palette.text.primary,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "transparent",
  },
  "& .MuiInputAdornment-root p": {
    fontSize: 12,
  },
  border: "none",
}));

export const FilterIcon = styled(Filter)(({ theme }) => ({
  width: 16,
  height: 15,
}));

export const IconButton = styled(MuiIconButton)(({ theme }) => ({
  "& svg": {
    width: 16,
    height: 16,
  },
}));

import { ReactElement } from "react";
import { renderToStaticMarkup } from "react-dom/server";

export { default as BackgroundAreaLines } from "../svg_icons/background_area_lines.svg?react";
export { default as BackgroundLines } from "../svg_icons/background_lines.svg?react";
export { default as CollaborationElement } from "../svg_icons/collaboration_element.svg?react";
export { default as CollaborationLogo } from "../svg_icons/collaboration_logo.svg?react";
export { default as CreateAccountIcon } from "../svg_icons/create_account.svg?react";
export { default as ClipPolygon } from "../svg_icons/clip_polygon.svg?react";
export { default as DeleteIcon } from "../svg_icons/delete.svg?react";
export { default as ExportIcon } from "../svg_icons/export_icon.svg?react";
export { default as HeaderElement } from "../svg_icons/header_element.svg?react";
export { default as ImportIcon } from "../svg_icons/import_icon.svg?react";
export { default as InvalidLinkIcon } from "../svg_icons/invalid_link.svg?react";
export { default as FONDLogo } from "../svg_icons/logo.svg?react";
export { default as MagnetIcon } from "../svg_icons/magnet.svg?react";
export { default as MergeIcon } from "../svg_icons/merge.svg?react";
export { default as NoContentIcon } from "../svg_icons/no-content.svg?react";
export { default as NoResourceIcon } from "../svg_icons/no-resource.svg?react";
export { default as PageErrorIcon } from "../svg_icons/page_error.svg?react";
export { default as PageNotFoundIcon } from "../svg_icons/page_not_found.svg?react";
export { default as PlannerElement } from "../svg_icons/planner_element.svg?react";
export { default as PlannerLogo } from "../svg_icons/planner_logo.svg?react";
export { default as PolygonIcon } from "../svg_icons/polygon.svg?react";
export { default as PointerIcon } from "../svg_icons/pointer.svg?react";
export { default as SplitPolygon } from "../svg_icons/split_polygon.svg?react";
export { default as UnionIcon } from "../svg_icons/union.svg?react";
export { default as UnmergeIcon } from "../svg_icons/unmerge.svg?react";
export { default as UploadIcon } from "../svg_icons/upload_icon.svg?react";
export { default as USFlagIcon } from "../svg_icons/us_flag.svg?react";
export { default as Wave } from "../svg_icons/wave.svg?react";
export { default as ZipFolderIcon } from "../svg_icons/zip_folder_icon.svg?react";
export { default as HandShakeIcon } from "svg_icons/handshake_icon.svg?react";
export { default as MoveIcon } from "svg_icons/move.svg?react";
export { default as DrawBoundaryIcon } from "svg_icons/draw_boundary.svg?react";

/*
 * Generate and return a static url for the SVG ReactElement asset.
 * Intended for use in styling an SVG background-image.
 * Example usage:
 *
 *   import { staticUrl, CollaborationLogo } from "fond/svg_icons";
 *
 *   <Box
 *     style={{
 *       backgroundImage: staticUrl(<CollaborationLogo fill={theme.palette.biarri.primary.red} />),
 *       backgroundColor: {theme.palette.biarri.primary.carbon},
 *       backgroundSize: "cover",
 *     }}
 *   >
 */

// Find any SVG tags that are missing the xmlns attribute. https://regexr.com/7vtu1
const regexSvgTagsNoNamespace = /(<svg)\s+((?![\s\S]*?xmlns=".*")[\s\S]*?>)/gim;
const ns = `xmlns="http://www.w3.org/2000/svg"`;

export const staticUrl = (icon: ReactElement): string => {
  const staticIcon = renderToStaticMarkup(icon);
  const iconWithXmlns = staticIcon.replaceAll(regexSvgTagsNoNamespace, `$1 ${ns} $2`);
  return `url("data:image/svg+xml,${encodeURIComponent(iconWithXmlns)}")`;
};
